<template>
  <div>
    <div class="pc-layout">
      <div class="article-module">
        <div class="article-module-banner">
          <div class="banner-container">
            <img :src="articleDetail.vCover + '?resize=1&w=1920'" alt="">
          </div>
          <div class="banner-content">
            <span>{{articleDetail.title}}</span>
          </div>
        </div>
        <div class="article-module-top">
          <div class="top-time">2022年11月15日</div>
<!--          <div class="top-tags">-->
<!--            <a href="">-->
<!--              <div class="top-tags-box">其它应用</div>-->
<!--            </a>-->
<!--          </div>-->
        </div>
        <div class="article-module-detail">
          <div class="detail-container" id="rich-text" v-html="articleDetail.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../utils/api";

// let defaultObj = {
//   author: '',
//   authorIcon: '',
//   content: '',
//   createTime: '',
//   vCover: '',
//   hCover: '',
//   id: '',
//   isDeleted: '',
//   isPublish: '',
//   label: '',
//   source: '',
//   subTitle: '',
//   title: '',
//   type: '',
//   updateTime: '',
// }

export default {
  data () {
    return {
      articleDetail: '',
    }
  },
  mounted() {
    this.getArticleDetail(this.$route.query.id);
  },
  methods: {
    getArticleDetail(id) {
      api.getArticleDetailData({
        id
      }).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.articleDetail = res.data.data;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
//pc端
@media only screen and (min-device-width: 700px) {
  .pc-layout {
    width: 100%;
    background: rgb(14, 17, 40);
    margin-top: 52px;
    overflow: visible;
    position: relative;

    .article-module {
      width: 100%;

      .article-module-banner {
        width: 100%;
        min-height: 50vh;
        margin-bottom: 25px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .banner-container {
          width: 100%;
          height: 100%;
          max-width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
          }
        }

        .banner-content {
          width: 730px;
          text-align: left;
          z-index: 1;

          span {
            color: #ffffff;
            font-size: 40px;
            font-weight: 700;
          }
        }
      }

      .article-module-top {
        width: 730px;
        margin: 0 auto;

        .top-time {
          width: 100%;
          //margin-bottom: 20px;
          padding: 0 0 10px;
          border-bottom: 1px solid rgb(32, 35, 55);
          font-size: 16px;
          color: rgb(219, 222, 227);
        }

        .top-tags {
          width: 100%;
          margin-bottom: 20px;
          
          a {
            margin-right: 5px;
            display: inline-block;
            transition: color 200ms ease-out 0s;

            .top-tags-box {
              height: 32px;
              background-color: rgb(32, 35, 55);
              margin-bottom: 8px;
              padding: 0 16px;
              box-sizing: border-box;
              border-radius: 200px;
              display: inline-block;
              text-align: center;
              color: #ffffff;
              font-size: 12px;
              letter-spacing: 1px;
              line-height: 32px;
              cursor: pointer;
            }
          }
        }
      }

      .article-module-detail {
        width: 100%;
        background: rgb(14, 17, 40);
        padding-bottom: 56px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;

        .detail-container {
          width: 1200px;
          margin: 0 auto;
        }
      }
    }
  }
}

//移动端
@media only screen and (max-device-width: 700px) {
  .pc-layout {
    width: 100%;
    background: rgb(14, 17, 40);
    margin-top: 52px;
    overflow: visible;
    position: relative;

    .article-module {
      width: 100%;

      .article-module-banner {
        width: 100%;
        min-height: 50vh;
        margin-bottom: 25px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .banner-container {
          width: 100%;
          height: 100%;
          max-width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
          }
        }

        .banner-content {
          width: 100%;
          padding: 20px 20px 0;
          box-sizing: border-box;
          text-align: left;
          z-index: 1;

          span {
            color: #ffffff;
            font-size: 28px;
            font-weight: 700;
            line-height: 38px;
          }
        }
      }

      .article-module-top {
        width: 100%;
        margin: 0 auto;

        .top-time {
          width: 100%;
          //margin-bottom: 20px;
          padding: 5px 20px;
          box-sizing: border-box;
          border-bottom: 1px solid rgb(32, 35, 55);
          font-size: 20px;
          color: rgb(219, 222, 227);
        }

        .top-tags {
          width: 100%;
          padding: 5px 20px 15px;
          box-sizing: border-box;

          a {
            margin-right: 5px;
            display: inline-block;

            .top-tags-box {
              height: 32px;
              background-color: rgb(32, 35, 55);
              margin-bottom: 8px;
              padding: 0 16px;
              box-sizing: border-box;
              border-radius: 200px;
              display: inline-block;
              text-align: center;
              color: #ffffff;
              font-size: 12px;
              letter-spacing: 1px;
              line-height: 32px;
              cursor: pointer;
            }
          }
        }
      }

      .article-module-detail {
        width: 100%;
        background: rgb(14, 17, 40);
        padding-bottom: 36px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;

        .detail-container {
          width: 100%;
          margin: 0 auto;
          padding: 0 20px;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>